import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Link } from 'react-router-dom';
import { login, logout } from '../redux/action';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
export default function Header(props) {
  const { setMenuExpand, menuExpand } = props;
  const dispatch = useDispatch()
  const history = useHistory()
  const LoginState = useSelector((state) => state.LoginState);

  return (
    <div className="row header-design"
    >
      <div className="col-6">
        <div className="bars-position"
          onClick={() => {
            setMenuExpand(!menuExpand)
          }}
        >
          <i className="fa fa-bars" style={{ fontSize: "20px" }} />
        </div>
      </div>
      <div className="col-6"
      >
        <div className="name-header-postion">
          {LoginState.token == null ?
            <div>
              <Link to={"/login"} className="Link">
                Login&nbsp;&nbsp;
              </Link>
              <i className="fa fa-sign-in" style={{ fontSize: "20px" }}
                onClick={() => {
                  history.push('/login')
                }}
              />
            </div>
            :
            <div>
              <Link to={"/profile"} className="Link">
                {LoginState.userName}&nbsp;&nbsp;
              </Link>
              <i className="fa fa-sign-out" style={{ fontSize: "20px" }}
                onClick={() => {
                  history.push('/')
                  dispatch(logout())
                }}
              />
            </div>
          }
        </div>
      </div>
    </div>
  )
}